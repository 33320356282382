.bb-portfolio {
    .picture-item {
        min-height: 250px;
        transition: all .3s;

        img {
            object-fit: cover;
            height: 100%;
        }

        .picture-item__details {
            position: absolute;
            opacity: 0;
            transition: all .3s;
            color: $white;
            z-index: 5;

            .picture-item__description {
                font-style: italic;
                margin-top: 1rem;
            }
        }

        &::before {
            transition: all .3s;
            opacity: 0;
            position: absolute;
            content: '';
            background-color: rgba(0, 0, 0, 0.67);
            width: 100%;
            height: 100%;
            z-index: 1;
            display: block;
            left: 0;
            top: 0;
        }

        &:hover {
            .picture-item__details {
                opacity: 1;
            }

            &::before {
                opacity: 1;

            }

        }

    }
}
// Portfolio v1
.bb-portfolio_v1 {
    .portfolio_v1--item {
        position: relative;

        .bg-overlay {
            background: rgba(0, 0, 0, .6);
            opacity: 0;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            -moz-transition: all .3s ease;

        }

        .media__content {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 99;
            overflow: hidden;

            .title {
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                -moz-transition: all .5s ease;
                transition: all .5s ease;
                opacity: 0;
                -webkit-transform: translateY(50px);
                -moz-transform: translateY(50px);
                -ms-transform: translateY(50px);
                -o-transform: translateY(50px);
                transform: translateY(50px);

                a {
                    font-size: 24px;
                    color: #fff;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }

            .address {
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                -moz-transition: all .5s ease;
                transition: all .5s ease;
                opacity: 0;
                -webkit-transform: translateY(50px);
                -moz-transform: translateY(50px);
                -ms-transform: translateY(50px);
                -o-transform: translateY(50px);
                transform: translateY(50px);
                opacity: 0;
                font-size: 11px;
                color: #ececec;
            }
        }

        .line {
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 9;
            width: -webkit-calc(50% - 20px);
            width: -moz-calc(50% - 20px);
            width: calc(50% - 20px);
            height: -webkit-calc(50% - 20px);
            height: -moz-calc(50% - 20px);
            height: calc(50% - 20px);
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            -moz-transition: all .3s ease;
            transition: all .3s ease;

            &::before,
            &::after {
                content: '';
                background: #fff;
                display: block;
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                -moz-transition: all .5s ease;
                transition: all .5s ease
            }

            &::before {
                height: 1px;
                width: 0;
            }

            &::after {
                width: 1px;
                height: 0;
            }
        }

        .line--bottom {
            top: auto;
            left: auto;
            bottom: 20px;
            right: 20px;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        &:hover {
            img {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }

            .bg-overlay {
                opacity: 1;
            }

            .media__content {
                .title {
                    opacity: 1;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    -o-transform: translateY(0);
                    transform: translateY(0);
                }

                .address {
                    opacity: 1;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    -o-transform: translateY(0);
                    transform: translateY(0);
                }

            }

            .line::before {
                width: 100%;
            }

            .line::after {
                height: 100%;
            }
        }
    }
}

// Portfolio v2
.bb-portfolio_v2 {
    .portfolio_v2--item {
        img {
            position: relative;
            overflow: hidden;
            -webkit-transition: all .5s ease;
            -o-transition: all .5s ease;
            -moz-transition: all .5s ease;
            transition: all .5s ease;
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
        }
        &:hover{
            img{
            transform: scale(1.08);
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
            }
        }
    }
}
// Portfolio 3 =============================================== //
.bb-portfolio_v3 {
    background-color: rgba(248, 248, 250, 1);
    .portfolio_v3--item {
        position: relative;

        .bg-overlay {
            background: rgba(255, 255, 255, .8);
            opacity: 0;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            -moz-transition: all .3s ease;
            &::before{
            background-color: rgba(242, 242, 247, .85);
            }

        }

        .media__content {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 99;
            overflow: hidden;

            .title {
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                -moz-transition: all .5s ease;
                transition: all .5s ease;
                opacity: 0;
                -webkit-transform: translateY(50px);
                -moz-transform: translateY(50px);
                -ms-transform: translateY(50px);
                -o-transform: translateY(50px);
                transform: translateY(50px);

                a {
                    font-size: 24px;
                    color: #000000;
                    font-weight: 300;
                    text-transform: uppercase;
                }
            }
        }

         &:hover {
            > div {
                @apply shadow-xl;
            }
            img {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }

            .bg-overlay {
                opacity: 1;
            }

            .media__content {
                .title {
                    opacity: 1;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    -o-transform: translateY(0);
                    transform: translateY(0);
                }

            }
        }
    }
}


// Portfolio 4 =============================================== //

.bb-portfolio_v4 {
    background-color: rgba(248, 248, 250, 1);
    .portfolio_v4--item {
        position: relative;

        .bg-overlay {
            background: rgba(255, 255, 255, .8);
            opacity: 0;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            -moz-transition: all .3s ease;
            &::before{
            background-color: rgba(0, 0, 0, .95);
            }

        }

        .media__content {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 99;
            overflow: hidden;

            .title {
                -webkit-transform: translateX(50px);
                -moz-transform: translateX(50px);
                -ms-transform: translateX(50px);
                -o-transform: translateX(50px);
                transform: translateX(50px);
                -webkit-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -o-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -moz-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                opacity: 0;

                a {
                    font-size: 30px;
                    color: #ffffff;
                    font-weight: 300;
                    text-transform: uppercase;
                }
            }
        }

         &:hover {
            > div {
                @apply shadow-xl;
            }
            img {
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }

            .bg-overlay {
                opacity: 1;
            }

            .media__content {
                .title {
                    opacity: 1;
                    -webkit-transform: translateX(0);
                    -moz-transform: translateX(0);
                    -ms-transform: translateX(0);
                    -o-transform: translateX(0);
                    transform: translateX(0);
                }

            }
        }
    }
}

// Portfolio 5 =============================================== //

.bb-portfolio_v5 {
    background-color: rgba(248, 248, 250, 1);
    .portfolio_v5--item {
        position: relative;

        .bg-overlay {
            background: rgba(255, 255, 255, .8);
            opacity: 0;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            -moz-transition: all .3s ease;
            &::before{
                background-color: rgba(223, 222, 222, 0.8);
            }

        }

        .media__content {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 10%;
            padding: 0 1rem;
            z-index: 99;
            overflow: hidden;
            -webkit-transition: all 350ms ease;
            -moz-transition: all 350ms ease;
            -ms-transition: all 350ms ease;
            -o-transition: all 350ms ease;
            transition: all 350ms ease;
            transition-delay: 0s;

            .title, .blog, .web {
                -webkit-transform: translateX(-50px);
                -moz-transform: translateX(-50px);
                -ms-transform: translateX(-50px);
                -o-transform: translateX(-50px);
                transform: translateX(-50px);
                -webkit-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -o-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -moz-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                opacity: 0;

                
            }
            .title {
                font-size: 20px;
                color: #5f6366;
                font-weight: 300;
                text-transform: uppercase;
                transition-delay: 70ms !important;
            }
            .blog {
                transition-delay: 140ms !important;
                position: relative;
                color: #5f6366;
                &::before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 0;
                    background-color: $white;
                    bottom: 0;
                    left: 0;
                    transition: .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                }
                &:hover {
                    &::before{
                        width: 100%;
                    }
                }
            }
            .web {
                color: #5f6366;
                transition-delay: 210ms !important;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 0;
                    background-color: $white;
                    bottom: 0;
                    left: 0;
                    transition: .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                }
                &:hover {
                    &::before{
                        width: 100%;
                    }
                }
            }
        }

         &:hover {
            img {
                transition: all .5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }

            .bg-overlay {
                opacity: 1;
            }

            .media__content {
                .title, .blog, .web {
                    opacity: 1;
                    -webkit-transform: translateX(0);
                    -moz-transform: translateX(0);
                    -ms-transform: translateX(0);
                    -o-transform: translateX(0);
                    transform: translateX(0);
                }

            }
        }
    }
}

// Portfolio 6 =============================================== //

.bb-portfolio_v6 {
    background-color: rgba(248, 248, 250, 1);
    .portfolio_v6--item {
        position: relative;

        .portfolio_v6--overlay {
            background: rgba(0, 0, 0, .8);
            opacity: 0;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -webkit-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
            -o-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
            -moz-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
            transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
            &::before{
            background-color: rgba(0, 0, 0, .95);
            }

        }

        .media__content {
            background-color: rgba(238, 238, 240, 1);
            color: rgba(92, 92, 92, 0.8);
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 99;
            overflow: hidden;
            padding: 1rem 0;
            -webkit-transform: translateY(100%);
                -moz-transform: translateY(100%);
                -ms-transform: translateY(100%);
                -o-transform: translateY(100%);
                transform: translateY(100%);
                -webkit-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -o-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -moz-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                opacity: 0;
        }

         &:hover {
            img {
                -webkit-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -o-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -moz-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -webkit-transform: translateY(-10%);
                -moz-transform: translateY(-10%);
                -ms-transform: translateY(-10%);
                -o-transform: translateY(-10%);
                transform: translateY(-10%);
            }

            .portfolio_v6--overlay {
                opacity: 1;
                -webkit-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -o-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                -moz-transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
                .portfolio_v6--links {
                    transition-delay: 200ms;
                }
            }

            .media__content {
                -webkit-transform: translateX(0);
                -moz-transform: translateX(0);
                -ms-transform: translateX(0);
                -o-transform: translateX(0);
                transform: translateX(0);
                opacity: 1;            

            }
        }
    }
}