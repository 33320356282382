$primary-color: #EEE5DB;
$secondary-color: #752f07;
$dark-blue:     #034089;
$title-color:   #18223b;
$body-color:    #3c3c41;
$light-grey:    #f5f5f8;
$text-grey:     #929296;
$white:         #ffffff;
$black:         #000000;
$gray:          #9d9ca2;
$dark-gray:     #515155;


//Font
$header-font:    'Lexend Deca', sans-serif !default;
$menu-font:     'Hind', sans-serif !default;
$button-font:  'Hind', sans-serif !default;
$body-font:     'Hind', sans-serif!default;

// Weight
$light:         300;
$regular:       400;
$medium:        500;
$semibold:      600;
$bold:          700;

// Responsive breakpoints
$size-xs: 480px !default;
$size-sm: 640px !default;
$size-md: 768px !default;
$size-lg: 1024px !default;
$size-xl: 1280px !default;
$size-2x: 1440px !default;
