@tailwind base;
@tailwind components;
@tailwind utilities;

// Main Styling
@import 'core/variables';
@import 'core/mixins';
@import 'core/core';
@import 'components/logo';
@import 'components/header';

// Section
@import 'layout/main_banner';
@import 'layout/portfolio';
@import 'layout/team';
@import 'layout/banner';
@import 'layout/about';
@import 'layout/philosophy';
@import 'layout/offer';
@import 'layout/cta';
@import 'layout/footer';
@import 'layout/mobile';