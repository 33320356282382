.we-philosophy__v1 {
    background: $primary-color;
    @include breakpoint(sm) {
        background: $black;
    }

    .we-philosophy_wrapper {
        margin: 3rem auto;
    }

    .we-philosophy-image {
        margin-top: -2.5rem;
        top: 0;
        right: 0;
        height: 112%;
        overflow: hidden;
    }
}