// overlays

.bg-overlay {
    position: relative;
    &::before{
        position: absolute;
        content: '';
        background-image: linear-gradient(to left, rgba(0,0,0,.4) 26%,rgba(255,255,255,0) 70%);
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;
        left: 0;
        top: 0;
    }
    .container {
        position: relative;
        z-index: 3;
    }
}
.dark-overlay {
    &__left {
        &::before{
        position: absolute;
        content: '';
         background-image: linear-gradient(to right, rgba(0,0,0,1) 26%,rgba(255,255,255,0) 70%);
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;
        left: 0;
        top: 0;
        }
        .bb-banner-content {
            z-index: 10;
        }
    }
    &__right {
        &::before{
        position: absolute;
        content: '';
         background-image: linear-gradient(to left, rgba(0,0,0,1) 26%,rgba(255,255,255,0) 70%);
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;
        left: 0;
        top: 0;
        }
        .bb-banner-content {
            z-index: 10;
        }
    }
}
.light-overlay {
    &__left {
        &::before{
        position: absolute;
        content: '';
         background-image: linear-gradient(to right, rgba(255,255,255,1) 26%,rgba(255,255,255,0) 70%);
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;
        left: 0;
        top: 0;
        }
        .bb-banner-content {
            z-index: 10;
        }
    }
    &__right {
        &::before{
        position: absolute;
        content: '';
         background-image: linear-gradient(to left, rgba(255,255,255,1) 26%,rgba(255,255,255,0) 70%);
        width: 100%;
        height: 100%;
        z-index: 1;
        display: block;
        left: 0;
        top: 0;
        }
        .bb-banner-content {
            z-index: 10;
        }
    }
}

// Main banners
.bb-title{
    position: absolute;
    font-size: 5rem;
    &__center{
        @extend .bb-title;
        left: 50%;
        transform: translateX(-50%);
        &-center {
            @extend .bb-title__center;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    &__left {
        @extend .bb-title;
        left: 22%;
        transform: translateX(-50%);
        &-center {
            @extend .bb-title__left;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    &__right {
        @extend .bb-title;
        right: 22%;
        transform: translateX(50%);
        &-center {
            @extend .bb-title__right;
            top: 50%;
            transform: translateX(50%) translateY(-50%);
        }
    }
}
