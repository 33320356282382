.bb-team {
    .team-item {
        min-height: 250px;
        transition: all .3s;
        text-align: center;
        margin: 3rem 0;

        img {
            position: absolute;
            top: -1rem;
            width: 160px;
            height: auto;
            transition: all .3s;
            z-index: 5;
        }

        .team-item__details {
            min-height: 250px;
            position: relative;
            top: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: end;
            overflow: hidden;
            padding: 1rem 1rem 2rem;

            .team-item__desc {
                position: absolute;
                transform: translateY(110%);
                color: $white;
                z-index: 10;
                opacity: 0;
                left: 0;
                padding: 0 1rem;
                transition: all .3s;
            }

            &::before {
                transition: all .3s;
                opacity: 0;
                position: absolute;
                content: '';
                background-color: rgba(0, 0, 0, 0.87);
                width: 100%;
                height: 100%;
                z-index: 1;
                display: block;
                left: 0rem;
                top: 0rem;
                border-radius: .5rem;
            }
        }



        &:hover {
            img {
                top: -5rem;
            }

            .team-item__details {
                opacity: 1;

                .team-item__desc {
                    position: absolute;
                    transform: translateY(10%);
                    opacity: 1;
                }

                &::before {
                    opacity: 1;

                }
            }



        }

    }
}

.bb-team_v3 {

    .bb-team__wrap {
        .bb-team__img {
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            -webkit-transition: all 350ms ease;
            -moz-transition: all 350ms ease;
            -ms-transition: all 350ms ease;
            -o-transition: all 350ms ease;
            transition: all 350ms ease;
        }

        .bb-team__social {
            visibility: hidden;

            a{
                @apply text-gray-100;
                text-decoration: none;
                display: inline-block;
                margin-top: 13px;
                font-size: 15px;
                opacity: 0;
                visibility: hidden;
                -webkit-transition: all 350ms ease;
                -moz-transition: all 350ms ease;
                -ms-transition: all 350ms ease;
                -o-transition: all 350ms ease;
                transition: all 350ms ease;
                transition-delay: 0s;
                &:nth-of-type(1) {
                    transition-delay: 50ms !important;
                }

                &:nth-of-type(2) {
                    transition-delay: 125ms !important;
                }

                &:nth-of-type(3) {
                    transition-delay: 200ms;
                }
            }
        }

        .bb-team__name {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                @apply bg-gray-600;
                -webkit-transition: all 450ms ease;
                -moz-transition: all 450ms ease;
                -ms-transition: all 450ms ease;
                -o-transition: all 450ms ease;
                transition: all 450ms ease;
            }
        }

        -moz-transition: all 350ms ease;
        -ms-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;

        &:hover {

            .bb-team__img {
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }

            .bb-team__social {
                a {
                    position: relative;
                    visibility: visible;
                    opacity: 1;

                    &:nth-of-type(1) {
                        transition-delay: 50ms !important;
                    }

                    &:nth-of-type(2) {
                        transition-delay: 125ms !important;
                    }

                    &:nth-of-type(3) {
                        transition-delay: 200ms;
                    }
                }
            }

            .bb-team__name {
                &::before {
                    width: 100%;
                }
            }


        }
    }
}

.bb-team_v4, .bb-team_v5{
    .bb-team__img {
        transition: all .35s ease;
        &:hover {
            transform: scale(1.04);
        }
    }
}