html {
    overflow-x: hidden;
}

body {
    font-family: $body-font;
    background: $primary-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    &.mobile-menu-opened {
        overflow-y: hidden;
        max-height: 100vh;
    }
}
.body-wrapper {
    opacity: 1;
    transition: 1.6s all;
    position: relative;
    overflow-x: hidden;
}

.body-wrapper.fade-out {
    opacity: 0;
    transition: none;
    overflow: hidden;
    max-height: 100vh;
}
h1,h2,h3 {
    font-family: $header-font;
    font-weight: $regular;
}
a, img {
    transition: all .25s;
}
.banner-title {
    font-size: 3rem;
    @include breakpoint(xl) {
        font-size: 2.8rem;
    }
    @include breakpoint(lg) {
        font-size: 2.5rem;
    }
    @include breakpoint(sm) {
        font-size: 2rem;
    }
}
.section-title {
    font-size: 2.3rem;
    @include breakpoint(xl) {
        font-size: 2.1rem;
    }
    @include breakpoint(lg) {
        font-size: 2rem;
    }
    @include breakpoint(sm) {
        font-size: 1.8rem;
    }
}

.text--shadow {
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.btn {
    transition: all .3s;
    font-family: $button-font;
    letter-spacing: 1px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    @apply tr-smooth;
    @apply font-medium border-2 uppercase border-transparent py-2 px-5;
    
    @include breakpoint(md) {
        margin-right: 0;
    }
    @include breakpoint(sm) {
        margin-right: 0;
    }
    
}

.btn-primary {
    @apply bg-transparent border-2 border-white text-white;
    

    &:hover {
        @apply text-white;
        cursor: pointer;
        background: $secondary-color;
    }
}

.btn-secondary {
    @apply bg-black text-white;

    &:hover {
        @apply border-white;
        cursor: pointer;
    }
}

.we-h{
    &__250 {
        height: 250px;
    }
    &__350 {
        height: 350px;
    }
    &__450 {
        min-height: 450px;
    }
    &__550 {
        height: 550px;
    }
    &__600 {
        min-height: 600px;
    }
    &__700 {
        min-height: 700px;
    }
    &__800 {
        min-height: 800px;
    }
    &__900 {
        min-height: 900px;
    }
    &__1000 {
        min-height: 1000px;
    }
    &__full {
        min-height: 100vh;
    }   
}

.tr-smooth {
    transition: all .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.arrow-bottom {
        position: absolute;
        bottom: -2.5rem;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        z-index: 10;
        transform: translateY(-100%);
}

.we-section-padding {
    padding: 4rem 0; 
    @include breakpoint(sm) {
        padding: 2rem 0;
    }
}
.we-border {
    border: 22px solid $primary-color;
    @include breakpoint(sm) {
        border: 10px solid $primary-color;
    }
}