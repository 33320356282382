.open-menu {
    position: relative;
    z-index: 30;

}
.menu-icon-wrapper {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    z-index: 25;
    @include breakpoint(lg) {
        top: 3rem;
    }

}
.we-mobile-menu {
    text-align: right;
    transform: translateX(150%);
    transition: .4s ease;
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 8rem;
    padding-bottom: 5rem;
    background: transparentize($black, 0.1);
    @include breakpoint(xs) {
        padding: 4rem 2rem 2rem;
    }
    ul {
        
        flex-direction: column;
        margin: 2rem auto 0;
        font-size: 2rem;
        .nav-link {
            margin-bottom: 1rem;
            display: block;
        }
    }

    &.opened {
        transform: translateX(0);

    }
}
