.we-footer{
    .we-copyright {
        color: $dark-gray;
    }

    .wpcf7 {
        .form-input {
            background: transparent;
            border-bottom: 1px solid $white;
            padding-bottom: .3rem;
            width: 100%;
        }
        .input-row {
            margin-bottom: 1.3rem;
        }

    }
    a {
        opacity: 1;
        transition: .25s ease;
        &:hover {
            color: $secondary-color;
        }
    }
}